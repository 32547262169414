<style type="text/css">
  @import "~@/assets/css/common.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  // import $ from "jquery"
  import {
    getExamInfo,
    getUserExamOne
  } from "@/api/examination_user";
  import {
    format
  } from "@/libs/common";
  import {
    getCjDetails
  } from "@/api/score/score.js";
  /**
   * 成绩详情
   */
  export default {
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "成绩详情",
        items: [{
            text: "成绩库管理",
          },
          {
            text: "成绩详情",
            active: true,
          },
        ],
        examInfo: "",
        sid: "",
        gradeData: [
          "cj",
          "cjcllx",
          "dah",
          "jmcj",
          "km",
          "kmmc",
          "kmtg",
          "rdrq",
          "wjlbm",
          "wjrdiy",
          "ycl",
          "zkzh",
        ],
        gradeDataList: [],
      };
    },
    methods: {
      dealData(data, index) {
        let obj = {};
        if (data["cj" + index] != null) {
          this.gradeData.map((key) => {
            obj[key] = data[key + "_" + index];
          });
          this.gradeDataList.push(obj);
          this.dealData(data, index + 1);
        }
      },

      // 详情
      getCjDeatails(params) {
        getCjDetails(params).then(res => {
          if (res.status) {
            this.examInfo = res.data
            this.dealData(res.data, 1)
          }
        })
      },
    },
    mounted() {
      if (this.sid) {
        this.getCjDeatails(this.sid)
      }
    },
    created() {
      this.sid = this.$route.query.sid ? this.$route.query.sid : ""
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <div class="outerCotent mt-3 score-list mb-3" id="gradePrint">
              <input type="hidden" class="msgValInput" value="" name="sid" />
              <div class="msgContent flexList fs-xs">
                <div class="w-100 msgLabel d-flex">
                  <div class="msgName flexList justify-content-center">
                    考试名称
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.ksmc }}
                  </div>
                </div>

                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    姓名
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.ksxm }}
                  </div>
                </div>
                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    身份证件号
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.zjhm }}
                  </div>
                </div>
                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    考试年份
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.ksnf }}
                  </div>
                </div>
                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    考生性别
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.xb }}
                  </div>
                </div>

                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    报考级别名称
                  </div>
                  <div class="msgVal flexList">{{ examInfo.bkjbmc || "" }}</div>
                </div>

                <div class="w-50 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center">
                    报考职业名称
                  </div>
                  <div class="msgVal flexList">
                    {{ examInfo.bkzymc|| "" }}
                  </div>
                </div>

                <div class="d-flex w-100" v-for="(item, index) in gradeDataList" :key="index">
                  <div class="w-50 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      科目{{index + 1}}名称
                    </div>
                    <div class="msgVal flexList">{{ item.kmmc }}</div>
                  </div>

                  <div class="w-50 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      科目{{index + 1}}成绩
                    </div>
                    <div class="msgVal flexList">{{ item.cj }}</div>
                  </div>
                </div>

                <div class="flexList w-100 msgLabel" style="height: 120px">
                  <div class="msgName flexList justify-content-center">
                    成绩说明
                  </div>
                  <div class="msgVal flexList" style="line-height: 30px">
                    <div class="p-2 line4">
                      "-1”代表缺考，说明考生报名但未参加考试。<br />
                      "-2”代表违纪，说明考生在考试过程中有违纪违规行为发生。<br />
                      “-4”代表成绩无效，说明考后发现因报考条件不符或者存在其它违纪行为导致科目成绩无效，或虽无违纪违规行为但未按考试要求作答，或者试卷作答内容雷同导致的科目成绩无效。
                    </div>
                  </div>
                </div>
                <!-- <div class="flexList w-100 msgLabel" style="height: 60px">
                  <div class="msgName flexList justify-content-center">
                    操作相关
                  </div>
                  <div class="msgVal flexList">
                    <button type="button" class="btn btn-info flexList mr-4 h30" @click="downloadScore">
                      <img src="@/assets/images/person/file-pdf.png" class="mr-2" />下载电子成绩单
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
